import { Container, Row, Col, Card } from "react-bootstrap"

export default function About() {
  document.title = "Mediavelocity About"
    return (
        <>
            <Container
        fluid
        className="text-white bg-gray"
        
      >
        <Row>
          <Col
            md={9}
            className="d-flex align-items-center justify-content-start pe-5"
          >
            <h1 className="my-5 text-uppercase ls-3 lead fw-bold" style={{fontSize:'6vw', lineHeight:'90%'}}>
             Creativity<span className="serif fst-italic text-lowercase"> Meet </span>Strategy 
            </h1>
          </Col>
        </Row>

      </Container> 
      <Container>
<p>Strategy, meet your creative team.</p>
<p>Why hire in-house when you can rent as much as you need for as long as you need?</p>

<Row>
  <Col ><Card>
    <p>Package 1</p>
    <p>2500</p>
<p>40 hours</p>
    </Card></Col>
  <Col></Col>
  <Col></Col>
</Row>
      </Container>

      {/* <Container fluid className="text-center p-5 d-flex flex-column">
          <h2 className="text-uppercase mt-5">Be amazing</h2>
          <h5 className="fst-italic mb-5 pb-0 mt-3">The most powerful messages are clear and concise.</h5>
          <h5 className="serif mb-5 pb-5 mt-3">Once clarity is achieved the refinement can begin.</h5>
      </Container> */}


      <Container fluid className="text-white d-flex align-items-center justify-content-center largeImageCover" style={{
        minHeight:"50vh",
        backgroundImage:
        "url(https://pro2-bar-s3-cdn-cf4.myportfolio.com/9c0c32ffbfb6bec1105e5a0038d455f6/f88ca87a-c585-4ed5-9ecf-245861f95ab2_rw_1920.jpg?h=5a83a8d6d63b2cf4c75e7becefb3a870)",
      }}>
          <h2 className="display-4 fw-bold mb-5 pb-0 mt-0 mx-5">The best creative solution is about cutting clutter.</h2>

      </Container>

      <Container fluid style={{backgroundColor:"#666"}} className="text-center p-5 d-flex flex-column text-white">
          <h2 className="text-uppercase mt-5">Your creative answer</h2>
          <p className="serif fst-italic mb-5 pb-5 mt-3">Brand Identity  |  Web and Mobile  |  Packaging  |  Environmental  |  Video  |
Photography  |  Programming  | Print </p>
      </Container>
        </>
    )
}
