import React, { useState } from "react";

function LoanCalculator() {
  const [amount, setAmount] = useState(100000); // Default to $100,000
  const [rate, setRate] = useState(5); // Default to 5%
  const [term, setTerm] = useState(15); // Default to 15 years

  // Helper functions
  const formatCurrency = (value) => `$${Number(value).toLocaleString()}`;
  const parseCurrency = (value) => value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

  // Calculate monthly payment
  const calculatePayment = () => {
    const principal = parseFloat(amount);
    const interestRate = parseFloat(rate) / 100 / 12;
    const numberOfPayments = parseInt(term) * 12;

    if (principal && interestRate && numberOfPayments) {
      return (
        (principal * interestRate) /
        (1 - Math.pow(1 + interestRate, -numberOfPayments))
      ).toFixed(2);
    } else {
      return "0.00";
    }
  };

  // Values used in the calculation
  const principal = parseFloat(amount);
  const monthlyInterestRate = parseFloat(rate) / 100 / 12;
  const numberOfPayments = parseInt(term) * 12;

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <h2>Loan Calculator</h2>
      <div style={{ marginBottom: "10px" }}>
        <label>Loan Amount: </label>
        <input
          type="text"
          value={formatCurrency(amount)}
          onFocus={(e) => setAmount(parseCurrency(e.target.value))}
          onChange={(e) => setAmount(parseCurrency(e.target.value))}
          onBlur={(e) => setAmount(Number(parseCurrency(e.target.value)))}
          style={{ width: "100%", padding: "5px" }}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label>Interest Rate (%): </label>
        <input
          type="number"
          value={rate}
          onChange={(e) => setRate(e.target.value)}
          style={{ width: "100%", padding: "5px" }}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label>Loan Term (Years): </label>
        <input
          type="number"
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          style={{ width: "100%", padding: "5px" }}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <h4>Monthly Payment: {formatCurrency(calculatePayment())}</h4>
      </div>

      {/* Work Section */}
      <div style={{ marginTop: "30px", padding: "10px", background: "#f8f9fa", border: "1px solid #ccc", borderRadius: "5px" }}>
        <h4>Show the Work:</h4>
        <p>
          <strong>Formula:</strong>{" "}
          <code>
            P = (A * r) / (1 - (1 + r)<sup>-n</sup>)
          </code>
        </p>
        <p>
          <strong>Where:</strong>
          <ul>
            <li>
              <code>P</code> = Monthly Payment
            </li>
            <li>
              <code>A</code> = Loan Amount ({formatCurrency(principal)})
            </li>
            <li>
              <code>r</code> = Monthly Interest Rate ({(monthlyInterestRate * 100).toFixed(3)}%)
            </li>
            <li>
              <code>n</code> = Total Number of Payments ({numberOfPayments})
            </li>
          </ul>
        </p>
        <p>
          <strong>Calculation:</strong> <br />
          Monthly Payment ={" "}
          <code>
            ({formatCurrency(principal)} * {(monthlyInterestRate * 100).toFixed(3)}%) / (1 - (1 + {(monthlyInterestRate * 100).toFixed(3)}%)<sup>-{numberOfPayments}</sup>)
          </code>
        </p>
      </div>
    </div>
  );
}

export default LoanCalculator;
