import { Container, Row, Col } from "react-bootstrap";
import "../App.css";
// import { Link } from "react-router-dom";

const Home = () => {
  document.title = "Mediavelocity Creative"
  return (
    <>
      {/* <Container
        fluid
        className="bg-secondary text-white largeImageCover"
        style={{
          backgroundImage:
            "url(https://pro2-bar-s3-cdn-cf.myportfolio.com/9c0c32ffbfb6bec1105e5a0038d455f6/19f302b2-505b-4ed6-9e01-5aca1145586e_rw_1200.jpg?h=50e593ea5b42948d444b9eab78253f2a)",
          backgroundImage:
            "url(https://pro2-bar-s3-cdn-cf5.myportfolio.com/9c0c32ffbfb6bec1105e5a0038d455f6/653f92285a840727152f6fb9_rw_1200.jpg?h=e9de3c76ed7ea5d663c881b72aa6ad6f)",
          backgroundPosition: "center",
        }}
      >
        <Row>
          <Col
            md={6}
            className="d-flex align-items-center justify-content-start pe-5"
            style={{ minHeight: "65vh" }}
          >
            <h1 className="text-uppercase lead ls-3" style={{fontSize:'8vw', lineHeight:'90%'}}>
              design makes the difference
            </h1>
          </Col>
        </Row>
      </Container> */}

      <Container>
        <Row style={{paddingTop:"100px", paddingBottom:"100px"}}>
          <Col lg={6} style={{maxHeight:"600px"}} className="d-flex justify-content-center">

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 599.7 599.7" height="600px" width="100%">
  <path d="M130.01 114.52h82.54c51.53 0 87.3 33.02 87.3 89.8s-34.02 89.05-89.55 89.05h-80.29V114.52Zm184.08 121.81h53.53c1.75 14.26 12.76 21.01 30.01 21.01 10.26 0 22.76-4 22.76-14.76s-12.01-13.51-34.02-19.01c-30.01-7.5-66.28-17.76-66.28-55.78 0-41.02 33.52-56.53 73.79-56.53 37.02 0 71.04 15.51 71.79 54.53h-51.78c-1.5-9.75-8.5-16.01-22.76-16.01-11.51 0-19.51 5.25-19.51 12.51 0 10.51 15.26 12.01 42.02 18.51 30.01 7.25 60.03 19.01 60.03 57.03s-34.77 59.78-77.04 59.78c-46.52 0-81.79-20.01-82.54-61.28Zm-55.51 230.82h-.5c-11.26 16.26-29.52 23.26-51.28 23.26-24.01 0-44.52-8.25-59.03-23.76-16.51-17.51-24.76-41.52-24.76-69.29 0-52.78 33.52-93.3 88.8-93.3 46.77 0 76.29 24.26 82.04 64.03h-50.53c-2.75-11.51-13.51-21.01-29.52-21.01-25.01 0-37.77 21.01-37.77 50.28s14.51 51.03 39.77 51.03c19.01 0 28.26-10.26 31.52-22.26h-23.76v-37.02h73.79v97.05h-34.77l-4-19.01Zm59.19-159.83h52.28l58.53 100.55h.5V307.32h50.53v178.84h-54.03l-56.78-101.3h-.5v101.3h-50.53V307.32Z" fill="#333132"/>
  <path d="M599.7 599.7H0V0h599.7v599.7ZM64.11 535.59h471.48V64.11H64.11v471.48Z" fill="#ae0000"/>
  <path d="M206.21 251.25c24.62 0 41.8-15.9 41.8-46.93s-16.16-47.7-41.8-47.7h-24.36v94.62h24.36Z" fill="#ae0000"/>
</svg></Col>
          <Col className="align-content-center fw-bold display-3 p-5">
          <p>DESIGN</p>
          <p>MAKES THE</p>
          <p>DIFFERENCE</p>
          </Col>
        </Row>
      </Container>

      {/* CASE STUDIES */}
      {/* <Container fluid className="parent m-0 p-0">
        <Row>
          <Col
            className="p-4 block position-relative"
            style={{
              backgroundColor: "#e8f0f4",
              backgroundImage:
                "url(https://mir-s3-cdn-cf.behance.net/project_modules/disp/d90a9f36680101.60dc70bf998b5.jpg)",
              backgroundPosition: "center top",
              backgroundSize: "cover",
              minHeight:"10hv",
            }}
          >
            <h2>view case study</h2>
            <a className="stretched-link" href="/compact">
              <h3>COMPACT FAMILY</h3>
            </a>
          </Col>

<Col md={6} 
            className="p-4 block position-relative"
            style={{ backgroundImage:
              "url(https://pro2-bar-s3-cdn-cf3.myportfolio.com/9c0c32ffbfb6bec1105e5a0038d455f6/4369d57a-f067-4ef4-a945-7fb217efdd14_rw_1200.jpg?h=d4378c35f5d4d5a5245da6c60f304043)"
              // ,backgroundPosition: "0px 10px"
 ,             backgroundSize: "cover", }}
          >
            <h2>view case study</h2>
            <a className="stretched-link" href="/hellmanns-salad-recipes">
              <h3>Hellmann's Salad</h3>
            </a>
          </Col>


        </Row>

      </Container> */}

      {/* APPROACH */}
      <Container
        fluid
        className="bg-secondary text-white p-5 text-center d-flex flex-column justify-content-center"
      >
        <h1 className="display-4 fw-bold text-uppercase mt-5">approach</h1>
        <p className="mb-5 mt-3 ls-3">
          There are three basic principles that define our approach. We work organically finding insights, illuminating ideas and consider how
          design is expressed to deliver great solutions.
        </p>

        <Row className="mb-5">
          <Col md={4}>
            <h3 className="my-4 fw-bold text-uppercase fs-3">Imagine</h3>
            <p>Brand Strategy</p>
            <p>Delivery Strategy</p>
          </Col>
          <Col md={4}>
            <h3 className="my-4 fw-bold text-uppercase fs-3">Design</h3>
            <p>Brand Identity</p>
            <p>Web and Mobile</p>
            <p>Packaging</p>
            <p>Environmental</p>
          </Col>
          <Col md={4}>
            <h3 className="my-4 fw-bold text-uppercase fs-3">Delivery</h3>
            <p>Print Production</p>
            <p>Digital Production</p>
            <p>Web Development</p>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className="p-5 text-center d-flex flex-column justify-content-center text-white"
        style={{backgroundImage:"url(https://pro2-bar-s3-cdn-cf1.myportfolio.com/9c0c32ffbfb6bec1105e5a0038d455f6/ceda7a3c-ffef-4990-bbf1-2ec5908ef4d1_rw_1920.jpg?h=3abff9724ec98b7ba4819976e40e93c9)",
      backgroundPosition:"right -500px top -350px",
      // backgroundSize:"300px cover",
      backgroundRepeat:"no-repeat",
      backgroundColor:"#050102",
      // backgroundOrigin:"50% 50%",
      }}
      >
        <Row className="my-5">
          <Col md={5} className="mb-4">
          <h1 className="fs-2 text-uppercase mt-5 fw-bold">Creative</h1>
        <p className="mb-5 mt-3">
        In addition to our traditional brand offerings, we partner with in-house design teams, agencies and entrepreneurs as an excellent creative solution.

        </p>
{/* <Link to="/#">
            <p className="text-uppercase ls-3 link-light text-decoration-none">Learn more {'>'}</p>
</Link>       */}
          </Col>
        </Row>
      </Container>



    </>
  );
};

export default Home;
